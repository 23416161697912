import React, { useState, useRef } from "react";
import Input from '../Input/Input';
// import emailjs from '@emailjs/browser';
import MyModal from "../UI/MyModal/MyModal";
import Loader from "../UI/Loader/Loader";
import { sendData } from "../../AP/sendToServer";

import cl from './Form.module.css';


const Form = () => {

   const form = useRef()
   // Pcks32hAH&%9&ZQ
   // service_5jpk28m

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");

  const [dataForm, setDataForm] = useState({
    name: '',
    email: '',
    phone: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const [modalText, setModalText] = useState("");
  const [loader, setLoader] = useState(false);

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   setLoader(true);

  //  emailjs.sendForm( /*сервис ID*/ 'service_5jpk28m', /*Template ID*/ 'template_wb66k6b', form.current, /*Public Key*/ 'gO96SS9af_UKcOTqX')
  //    .then((result) => {

  //     setLoader(false);
  //     console.log(result.text);
  //     setModalText('Ваша заявка принята');
  //     setActive(true);
  //     // alert('Ваша заявка принята') //TODO: Нужно исправить проблему - не использовать alert
  //    }, (error) => {
  //     setLoader(false);
  //     console.log(error.text);
  //    });
  //    e.target.reset();
  // };

  const sendEmail = (e) => {
    e.preventDefault();

    for (const property in dataForm) {
      if (dataForm[property] === '') {
        setModalText('Все поля должны быть заполнены');
        setActive(true);
        return
      }
    }
    setLoader(true);


  sendData('https://vletivaiti.ru/form/', 'POST', dataForm)
    .then(() => {

      setActive(true);
      setModalText('Ваша заявка принята');

      setDataForm({
        name: '',
        email: '',
        phone: '',
      });
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    })
  }

  return (
    <form ref={form} className={cl.form} action="../../../../app.js" method="POST" onSubmit={sendEmail}>
      <p className={cl.text}>КТО БУДЕТ ОБУЧАТЬСЯ?</p>
      <div className={cl.inputs}>
        <Input
          type="text"
          placeholder='ИМЯ'
          name='user_name'
          value={dataForm.name}
          onChange={(event) => setDataForm({ ...dataForm, name: event.target.value })} /> <br />
        <Input
          type="email"
          placeholder='E-MAIL'
          name='user_email'
          value={dataForm.email.toString()}
          onChange={(event) => setDataForm({ ...dataForm, email: event.target.value })} />
        <Input
          type="tel"
          placeholder='НОМЕР ТЕЛЕФОНА'
          name='user_phone'
          value={dataForm.phone}
          onChange={(event) => setDataForm({ ...dataForm, phone: event.target.value })}
          style={{ marginTop: '20px' }} />
      </div>
      <input  type="submit" className={cl.button} disabled={loader} value="ОТПРАВИТЬ"/>
      {error && <div>Ошибка</div>}
      <MyModal active={active} setActive={setActive} setModalText={setModalText}>
        <div className={cl.form__message}>
          <span>{modalText}</span>
        </div>
      </MyModal>
      {
        loader ?
          <div className={cl.form__loader}>
            <Loader />
          </div>
        :
          ''
      }

    </form>
  );
};

export default Form;
