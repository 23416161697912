import React from 'react'
import Rocket from '../images/SliderRocket.png'
import Slider from './Slider/Slider';
import SliderAdaptive from './SliderAdaptive/SliderAdaptive';

const SliderGroup = () => {
   return (
      <div className='slider__container'>
         <div className="slider__header">
            <div className="slider__text">
               <p className="slider__title">
               Отзывы учеников
               </p>
               <p className="slider__desc">
               мне важно, чтобы вы приняли взвешенное решение об обучении. Поэтому я собрал для вас честные мнения выпускников “Влети в айти”&nbsp;—&nbsp;так вы сможете оценить все плюсы и минусы и найдёте ответы на волнующие вопросы.
               </p>
            </div>
            <img src={Rocket} alt="rocket" className="slider__img" />
         </div>
         <div className="slider__wrapper">
            <Slider/>
         </div>
         <div className="slider__wrapper-adaptive">
            <SliderAdaptive/>
         </div>
         
      </div>
   )
};

export default SliderGroup;