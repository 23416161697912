import './App.css';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/Main';
import ContractOffer from './pages/ContractOffer';
// import { router } from './index.js';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
    },
    {
        path: "/contract-offer",
        element: <ContractOffer />,
    },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
