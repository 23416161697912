import React, { useState } from "react";
import cl from './Slider.module.css';
import Left from '../../images/Left.png';
import Right from '../../images/Right.png';
import FirstRating from '../../images/FirstRating.png';
import SecondRating from '../../images/SecondRating.png';
import ThirdRating from '../../images/ThirdRating.png';
import FirstGood from '../../images/FirstGood.svg';
import SecondGood from '../../images/SecondGood.svg';
import ThirdGood from '../../images/ThirdGood.svg';

const Slider = () => {

  const [Clicked1, SetClicked1] = useState(false);
  const [Clicked2, SetClicked2] = useState(false);
  const [Clicked3, SetClicked3] = useState(false);
  const [count, SetCount] = useState(0)

  const images = [FirstRating, SecondRating, ThirdRating, ThirdRating, FirstRating];
  const goodImages = [FirstGood, SecondGood, ThirdGood, SecondGood, FirstGood]

  const handleClick1 = (e) => {
    SetClicked1(!Clicked1);
  };

  const changePhotoRight = () => {
   if (count < images.length - 1) {
      count < images.length - 3 ? SetCount(count + 1) : SetCount(0)
   }
   console.log(count);
  }

  const changePhotoLeft = () => {
   if (count > 0) {
   SetCount(count - 1)
   }
   console.log(count);
  }


  const handleClick2 = (e) => {
    SetClicked2(!Clicked2);
  };

  const handleClick3 = (e) => {
    SetClicked3(!Clicked3);
  };
  const handleClick = (e) => {
   if (e.target.alt === 'rating') {
      SetClicked1(false);
      SetClicked2(false);
      SetClicked3(false);
   }
  }

  return (
    <div onClick={handleClick}>
      <div className={cl.ratings}>
      <img onClick={changePhotoLeft} className={cl.left} src={Left} alt="back" />
        <div className={cl.rating}>
          <img src={images[count]} alt="message" className={cl.rating__img} onClick={handleClick1}/>
          {Clicked1
            ? <img src={goodImages[count]} className={cl.new__rating__img} alt='rating'/>
            : <></>
            }
  
        </div>
        <div className={cl.rating}>
          <img src={images[count + 1]} alt="message" className={cl.rating__img} onClick={handleClick2}/>
          {Clicked2
            ? <img src={goodImages[count + 1]} className={cl.new__rating__img} alt='rating'/>
            : <></>
            }
        </div>
        <div className={cl.rating}>
          <img src={images[count + 2]} alt="message" className={cl.rating__img} onClick={handleClick3}/>
          {Clicked3
            ? <img src={goodImages[count + 2]} className={cl.new__rating__img} alt='rating'/>
            : <></>
            }
        </div>
        <img onClick={changePhotoRight} className={cl.right} src={Right} alt="next" />
      </div>
     <a href='#FORM' style={{textDecoration: 'none'}}><button className={cl.link}>ХОЧУ НА ОБУЧЕНИЕ</button></a>
      </div>
   )
};

export default Slider;