import React from "react";
import PhotoMe from "../images/PhotoMe.png";
import Stars from "../images/Stars.png";
import Logo from "./Logo/Logo";
import HeaderImage from '../images/Фото.png'
import Button from "./Button/Button";

const Header = () => {
   return (
      <div className="header">
         <div className="header__left">
            <Logo />
            <div className="header__text">
               <h3 className="big-text">Тренируйся. Создавай. Получай доход.</h3>
               <p className="small-text">Ты получишь много теории и практики программирования. Мы&nbsp;даем тебе проект, а затем упаковываем и продаем его. Ты&nbsp;зарабатываешь, ещё не завершив обучение!</p>
            </div>
            <div className="header__background-adaptive">
               <img src={Stars} alt="" className="header__image-background" />
               <img src={PhotoMe} alt="" className="header__image"/>
            </div>
            
            <a href='#FORM' style={{ textDecoration: 'none' }} className="header__link">
               <Button value="получить неделю обучения бесплатно"/>
            </a>
         </div>
         <div className="header__right">
            <img src={HeaderImage} alt="" className="header__image" />
         </div>
      </div>
   )
};

export default Header;