import React from "react";
import Header from "../components/Header";
import Features from '../components/Features/Features';
import Steps from '../components/Steps/Steps';
import About from '../components/About';
import SelectStage from '../components/SelectStage/SelectStage';
import Offer from '../components/Offer/Offer';
import FormGroup from '../components/FormGroup';
import SliderGroup from '../components/SliderGroup';
import AboutMe from '../components/AboutMe/AboutMe';
import About2 from '../components/About2/About2';
import QAGroup from '../components/QAGroup/QAGroup';
import Footer from '../components/Footer/Footer';
import ImageAdaptive from "../images/Component.png";

const Main = () => {
   return (
      <div className="container">
         <Header/>
         <Features/>
         <About/>
         <Steps/>
         <SelectStage/>
         <Offer/>
         <FormGroup/>
         <SliderGroup/>
         <AboutMe/>
         <About2/>
         <div className='calculator'>
            <h1 className='calc__header'>МИНИМУМ ТЕОРИИ, 80% ПРАКТИКИ! </h1>
         </div>
         <QAGroup/>
         <Footer />
         <div className='image_adaptive'>
            <img src={ImageAdaptive} alt="" />
         </div>
      </div>
   )
};

export default Main;