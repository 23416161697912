import React from 'react'
import { motion } from 'framer-motion';
import Form from './Form/Form';
import Rocket from '../images/SliderRocket.png';

const FormGroup = () => {

   function FadeInWhenVisible({ children }) {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
        >
          {children}
        </motion.div>
      );
    }

    function FadeInWhenVisibleText({ children }) {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          className="form__wrapper"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, x: 0, scale: 1 },
            hidden: { opacity: 0, x: -100, scale: 0 }
          }}
        >
          {children}
        </motion.div>
      );
    }

   return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a name='FORM'>
      <div className='form__container'>
         <div className="left__part">
           <div className="left__img">
             <img src={Rocket} alt="rocket" />
           </div>
            <FadeInWhenVisibleText>
              <p className="left__part__header"><span className='free__line'>запишитесь <span className='free'>бесплатно</span></span> на&nbsp;пробную неделю обучения!</p>
            </FadeInWhenVisibleText>
            <FadeInWhenVisibleText>
            <p className="left__part__text">Знакомство с таким языком программирования, как <b>PHP</b>, также мы затронем <b>HTML</b>. А еще вы можете познакомиться с основами <b>Angular</b>. <br />Также вы увидите, как занимаются ученики основной группы и какие проекты они создают! <br /> <br /> <b>Пробная неделя</b> - это крутая возможность прикоснуться к внутренним процессам компании и посмотреть, как происходит разработка IT продуктов!</p>
            </FadeInWhenVisibleText>
        </div>
        <div className="right__part">
            <FadeInWhenVisible>
            <Form/>
            </FadeInWhenVisible>
         </div>
      </div>
    </a>
   )
};

export default FormGroup;