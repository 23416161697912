import React from 'react'

const CheckButton = (props) => {
   return (
      <button className='button__select__stage' onClick={props.handleClick} id={props.id} style={props.style}>
         {props.value}
      </button>
   )
};

export default CheckButton;