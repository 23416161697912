import React from 'react'
import Rocket from '../../images/Airplane3.png';
import Airplane from '../../images/Airplane.png';

import cl from './Logo.module.css';

const Logo = () => {
   return (
      <div className={cl.logo__container}>
         <div className={cl.logo__title}>
            <div className={cl.logo__img_adaptive}>
               <img src={Airplane} alt="airplane" />
            </div>
            <h1 className={cl.logo__text}>ВЛЕТИ В АЙТИ</h1>
         </div>
         <img className={cl.logo__img} src={Rocket} alt="rocket" />
      </div>
   )
};

export default Logo;