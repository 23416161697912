import React, { useState, useEffect } from 'react'
import cl from './QA.module.css'
import Plus from '../../images/Plus.png'
import Minus from '../../images/Minus.png'

const QA = (props) => {
   const [Image, SetImage] = useState(Plus);
   const [Visible, SetVisible] = useState(false);

   const handleClick = () => {
      SetImage((Image === Plus) ? Minus : Plus);
      SetVisible(!Visible)
      console.log(Visible);
   }

   useEffect((Visible) => {
      SetVisible(!Visible);
   }, [])   

   return (
      <>
      <div className={cl.container} onClick={handleClick}>
         <p className={cl.question}>{props.question}</p>
         <img src={Image} alt="plus" className={cl.plus}/>
      </div>
      {Visible
      ?  <></>
      : <div className={cl.answer__container}><p className={cl.answer}>{props.answer}</p></div>
      
      }
      </>
   )
};

export default QA;
