import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// password for emailjs - Pcks32hAH&%9&ZQ

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <App/>
);

