import React from 'react'
import cl from './QAGroup.module.css'
import QA from '../QA/QA';

const QAGroup = () => {
   return (
      <div className={cl.container}>
         <p className={cl.header}>вопросы и ответы</p>
         <p className={cl.desc}>здесь можно узнать подробнее о курсе</p>
         <QA
            question='Какая программа обучения?'
            answer='Ты узнаешь за 6 месяцев несколько языков, фрейморков.
            Ты будешь разрабатывать реальны проект, которым будут пользоваться люди.
            У тебя будет личный кабинет и созвоны со мной и куратором 1 раз в неделю.
            Будешь много практиковаться, научишься проходить собеседования и составлять резюме.
            '
         />
         <QA
            question='в каком формате проходит обучение?'
            answer='Это еженедельные встречи в зуме, просмотр видео уроков, выполнение заданий'
         />
         <QA
            question='5 лет в обучении?'
            answer='Не нужно 5 лет ходить в университет.
            Обучайся с нами и зарабатывай.

            Не нужно тратить время на общие групповые задания.
            Делай индивидуальные проекты, которые будут приносить прибыль с самого начала.
            '
         />
         <QA
            question='Какой документ я получу после окончания обучения?'
            answer='Ты получишь более 12 сертификатов.
            Создашь личные проекты.
            Можешь получить диплом гос образца.
            '
         />
         <QA
            question='Какие есть дополнительные возможности?'
            answer='Backend -
            Язык PHP на фреймворках Bitrix FrameWork, Slim и Yii2.
             Либо C# с использованием ASP Core.

             Frontend -
            На фреймворке Angular с помощью Node JS и TypeScript.

            Хочешь обучаться Phalcon, Unity и Laravel?
            Хочешь получить специализацию на React или Vue?
            Знаешь Angular, Slim, Yii2, ASP Core, Bitrix Framework, Node JS, PHP, TypeScript или Git?
            Подавай заявку уже сегодня, чтобы узнать больше!
            '
         />
         <QA
            question='сколько стоит обучение?'
            answer='Для удобства мы разбили обучение 3 части:

             2 месяца за 79 000 рублей,
             6 месяцев за 149 000 рублей,
             год с трудоустройстом 390 000 рублей.
             Рассрочки без процентов на срок от 3 до 24 месяцев.
             Полный курс тебе обойдется всего в 6 300 рублей в месяц.

            '
         />
         <QA
            question='как попробовать бесплатно?'
            answer='Заполни форму - и запишись на бесплатную неделю обучения'
         />
      </div>
   )
};

export default QAGroup;
