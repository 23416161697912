import React from 'react'
import Step from '../Step/Step';
import cl from './Steps.module.css'

const Steps = () => {
   return (
      <div className={cl.overall__container}>
         <div className={cl.container}>
            <h1 className={cl.header}>пять шагов к началу карьеры</h1>
            <div className={cl.steps__container}>
               <hr className={cl.hr} />
               <Step
                  num='1'
                  value='заполни форму'
               />
               <Step
                  num='2'
                  value='Выбери программу "Влети в айти"
                  для обучения'
               />
               <Step
                  num='3'
                  value='Изучи программирование
                  с самых основ и
                  Участвуй в реальных
                  IT проектах '
               />
               <Step
                  num='4'
                  value='закончи обучение'
               />
               <Step
                  num='5'
                  value='Получи возможность трудоустройства в мою компанию'
               />
            </div>
         </div>
      </div>
   )
};

export default Steps;