import React from 'react'
import cl from './Features.module.css'
import Rocket from '../../images/FeaturesRocket.png'
import Feature from '../Feature/Feature';

const Features = () => {
   return (
      <div className={cl.container}>
         <div className={cl.features}>
         <img src={Rocket} alt="" className={cl.rocket}/>
            <Feature
               value="удобное
               расписание"
            /> <hr className={cl.hr__top}/>
            <Feature
               value="удобный
               формат обучения"
            />
            </div>
            <div className={cl.sides}>
            <hr className={cl.side}/>
            <hr className={cl.side__2}/>
            <hr className={cl.side__3}/>
            </div>
            <div className={cl.features}>
            <Feature
               value="профессиональные
               преподаватели"
            /> <hr className={cl.hr__top}/>
            <Feature
               value="быстрый
               карьерный рост"
            />
         </div>
      </div>
   )
};

export default Features;