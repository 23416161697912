import React from 'react'
import { motion } from 'framer-motion';
import cl from './Offer.module.css'

const Offer = () => {

   function FadeInWhenVisible({ children }) {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
        >
          {children}
        </motion.div>
      );
    }

   return (
      <div className={cl.container}>
         <h2 className={cl.header}>мы предлагаем современный и гибкий формат обучения, который подойдет каждому!</h2>
         <FadeInWhenVisible>
         <div className={cl.line}>
            <div className={cl.group}>
               <p className={cl.little}>платформа</p>
               <p className={cl.big}>WhatsApp</p>
            </div>
            <div className={cl.group}>
                  <p className={cl.little__uppercase}>длительность</p>
               <p className={cl.big__uppercase}>6 месяцев</p>
            </div>
            <div className={cl.group}>
               <p className={cl.little__uppercase}>в группе</p>
               <p className={cl.big__uppercase}>20 человек</p>
            </div>
            <div className={cl.group__last}>
               <p className={cl.little__uppercase}>график</p>
               <p className={cl.big__uppercase}>свободный</p>
            </div>
         </div>
         </FadeInWhenVisible>
      </div>
   )
};

export default Offer;
