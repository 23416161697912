import React from "react";

import cl from "./MyModal.module.css";


const MyModal = ({children, ...props}) =>{
    const modalClass = [cl.myModal];
    const modalClassBlock = [cl.myModal__block, cl.myModal__block_scroll];
    const disableClass = [cl.myModal__disable];

    if (props.active) {
        modalClass.push(cl.active);
        modalClassBlock.push(cl.active);
    }

    const closeMyModal = () => {
        props.setActive(false);
        props.setModalText('');
    }
    
    return (
        <div 
            className={modalClass.join(' ')} 
            onClick={() => {props.setActive(false)}}>
            
            <div onClick={(e) => e.stopPropagation()} className={modalClassBlock.join(' ')}>
                <div className={disableClass.join()}></div>
                <div className={cl.myModal__close}>
                    <span onClick={() => {closeMyModal()}}>&#10006;</span>
                </div>
                {children}
            </div>
        </div>
    )
}

export default MyModal;