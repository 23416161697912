import React from 'react'
import cl from './Feature.module.css'

const Feature = (props) => {
   return (
      <div className={cl.feature}>
         {props.value}
      </div>
   )
};

export default Feature;