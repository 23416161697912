import React from 'react'
import cl from './SelectStage.module.css'
import left from '../../images/LeftRocket.png'
import right from '../../images/RightRocket.png'
import SelectStageForm from './../SelectStageForm/SelectStageForm';

const SelectStage = () => {
   return (
      <div className={cl.container}> 
         <img src={left} alt="rocket" className={cl.left}/>
         <img src={right} alt="rocket" className={cl.right}/>
         <SelectStageForm/>
      </div>
   )
};

export default SelectStage;