import React from 'react'
import cl from './About2.module.css'
import Photo from '../../images/PhotoMe.png'
import Stars from '../../images/Stars.png'

const About2 = () => {
   return (
      <div className={cl.container}>
         <div className={cl.left}>
            <img src={Stars} alt="stars" className={cl.stars}/>
            <img src={Photo} alt="" className={cl.photo}/>
         </div>
         <div className={cl.right}>
            <p className={cl.header}>
            хочешь учиться и <span className={cl.color}>развиваться?</span>
            </p>
            <ul className={cl.ul}>
               <li className={cl.li}>Во время обучения ты пройдешь теорию вместе с одногруппниками</li>
               <li className={cl.li}>Будешь много практиковаться и создавать дипломные проекты</li>
               <li className={cl.li}>Поймешь что нравится больше -Фронт или Бэк</li>
               <li className={cl.li}>Научишься делать интеграции и размещать проекты на хостинге</li>
               <li className={cl.li}>При выпуске приложений будешь получать стипендию в виде процента</li>
               <li className={cl.li}>Сможешь работать с нами после обучения</li>
            </ul>
         </div>
      </div>
   )
};

export default About2;