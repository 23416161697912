import React from 'react'
import Airplan from "../../images/Airplane.png"
import ImageAdaptive from "../../images/Component.png"
import Rocket from '../../images/FooterRocket.png'
import WhatsApp from '../../images/Whatsapp.png'
import Vk from '../../images/VK.png'
import Telegram from '../../images/Telegram.png'
import FooterComponent from '../../images/FooterComponent.png'
import { Link } from 'react-router-dom'

import cl from './Footer.module.css'

const Footer = () => {
   return (
      <div className={cl.container}>
         <img src={FooterComponent} alt="line" className={cl.footer__component}/>
         <div className={cl.line}>
            <div className={cl.left}>
               <div className={cl.logo}>
                  <p className={cl.logo__text}>ВЛЕТИ В АЙТИ</p>
                  <img src={Rocket} alt="rocket" className={cl.logo__img} />
                  <div className={cl.logo__airplan}>
                     <img src={Airplan} alt="airplan" />
                  </div>
               </div>
               <p className={cl.data}>
                  ИП Соловьев Алексей Сергеевич <br/>
                  ИНН: 631302369240 <br/>
                  ОГРНИП: 320631300043871 <br/>
                  Адрес регистрации: г. Самара <br/>
                  Р/С: 40802810800001459387 <br/>
                  Банк: АО "ТИНЬКОФФ БАНК"
               </p>
               <Link to="/contract-offer" className={cl.link} >Договор оферты</Link>
            </div>
            <div className={cl.right}>
      <p className={cl.contacts__header}>КОНТАКТЫ:</p>
      <p className={cl.contacts}>
      +7 (964) 569-83-50 <br />
      sale@vletivaiti.ru
      </p>
               <div className={cl.socials}>
                  <a href='https://clck.ru/3C7WQM' target="blank">
                     <img src={WhatsApp} alt="WhatsApp" className={cl.social__img}/>
                  </a>
                  <a href="https://t.me/Vleti_V_AiTi" target="blank">
                     <img src={Telegram} alt="Telegram" className={cl.social__img}/>
                  </a>
                  {/*<a href="https://vk.ru/soloit.study" target="blank">*/}
                  {/*   <img src={Vk} alt="VK" className={cl.social__img}/>*/}
                  {/*   </a>*/}

               </div>
            </div>
         </div>
      </div>
   )
};

export default Footer;
