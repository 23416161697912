import React from 'react'
import cl from './Butoon.module.css'

const Button = (props) => {
   return (
      <button className={cl.button}>
         {props.value}
      </button>
   )
};

export default Button;