import React from 'react'
import cl from './Input.module.css'

const Input = (props) => {
   return (
      <input 
         className={cl.input}
         placeholder={props.placeholder}
         value={props.value}
         onChange={props.onChange}
         name={props.name}
         style={props.style}
         type={props.type}
      />
   )
};

export default Input;