import React, { useState } from 'react';
import { motion } from 'framer-motion';
import cl from './SelectStageForm.module.css';
import CheckButton from '../CheckButton';

const SelectStageForm = () => {

   function FadeInWhenVisible({ children }) {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
        >
          {children}
        </motion.div>
      );
    }

   const [Title, setTitle] = useState('для новичков');
   const [Desc, setDesc] = useState('Присоединяйся для уверенного взлёта. Мы начнем с основ программирования и постепенно перейдем к созданию полноценных проектов. Ты познакомишься с различными инструментами и технологиями, которые помогут тебе стать самостоятельным и уверенным разработчиком.');

   const [Background1, SetBackground1] = useState('#fff')
   const [Background2, SetBackground2] = useState('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   const [Background3, SetBackground3] = useState('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   const [Shadow1, SetShadow1] = useState('0px 0px 8px 3px #00000033 inset')
   const [Shadow2, SetShadow2] = useState('4px 4px 7px 4px #EACC52')
   const [Shadow3, SetShadow3] = useState('4px 4px 7px 4px #EACC52')

   const titles = ['ДЛЯ НОВИЧКОВ', 'ДЛЯ НАЧИНАЮЩИХ', 'ДЛЯ ПРОФИ']
   const descs = ['Присоединяйся для уверенного взлёта. Мы начнем с основ программирования и постепенно перейдем к созданию полноценных проектов. Ты познакомишься с различными инструментами и технологиями, которые помогут тебе стать самостоятельным и уверенным разработчиком.', 'Если у тебя уже есть опыт в IT, будем рады тебе в нашей команде! Ты будешь работать над самыми ходовыми проектами и узнаешь о новейших технологиях и трендах в IT.', 'Присоединяйся к нам и стань частью команды, которая создает уникальные и успешные проекты уже сегодня!']

   const handleClick = (e) => {
   if (e.target.id === '0') {
   setTitle(titles[0])
   setDesc(descs[0])
   SetBackground1('#fff')
   SetBackground2('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetBackground3('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetShadow1('0px 0px 8px 3px #00000033 inset')
   SetShadow2('4px 4px 7px 4px #EACC52')
   SetShadow3('4px 4px 7px 4px #EACC52')
   }
   if (e.target.id === '1') {
   setTitle(titles[1])
   setDesc(descs[1])
   SetBackground1('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetBackground2('#fff')
   SetBackground3('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetShadow1('4px 4px 7px 4px #EACC52')
   SetShadow2('0px 0px 8px 3px #00000033 inset')
   SetShadow3('4px 4px 7px 4px #EACC52')
   }
   if (e.target.id === '2') {
   setTitle(titles[2])
   setDesc(descs[2])
   SetBackground1('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetBackground2('linear-gradient(263.76deg, #FFDE59 5.04%, #FFECA2 96.39%)')
   SetShadow1('4px 4px 7px 4px #EACC52')
   SetShadow2('4px 4px 7px 4px #EACC52')
   SetShadow3('0px 0px 8px 3px #00000033 inset')
   SetBackground3('#fff')

   }
   console.log(e.target);
   }

   return (
    <div className={cl.over__container}>
      <div className={cl.container}>
        <div className={cl.headers}>
          <p className={cl.header}>КОМУ ПОДХОДИТ</p>
          <p className={cl.question}>?</p>
         </div>
        <div className={cl.buttons}>
          <CheckButton
            style={{background: Background1, boxShadow: Shadow1}}
            value="Я НОВИЧОК"
            handleClick={handleClick}
            id={0}
          />
          <CheckButton
            style={{background: Background2, boxShadow: Shadow2}}
            value="Я НАЧИНАЮЩИЙ"
            handleClick={handleClick}
            id={1}
          />
          <CheckButton
            style={{background: Background3, boxShadow: Shadow3}}
            value="Я ПРОФИ"
            handleClick={handleClick}
            id={2}
          />
        </div>
       </div>
      <div className={cl.headers__adaptive}>
        <div className={cl.headers__adaptive_text}>
          <span>Для кого</span>
          <span>подходит</span>
          <span>обучение</span>
        </div>
        <div className={cl.headers__adaptive_symbol}>
          <span>?</span>
        </div>
      </div>
      <div className={cl.description}>
        <FadeInWhenVisible>
        <p className={cl.for__who}>{Title}</p>
        <p className={cl.desc__for__who}>{Desc}</p>
        </FadeInWhenVisible>
      </div>
    </div>
   )
};

export default SelectStageForm;
