import React from 'react'
import cl from './Step.module.css'

const Step = (props) => {
   return (
      <div className={cl.step}>
         <div className={cl.num__container}>
            <p className={cl.num}>{props.num}</p
            ></div>
         <p className={cl.value}>{props.value}</p>
      </div>
   )
};

export default Step;