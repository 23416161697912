import React, {useEffect, useRef, useState} from "react";
import { motion, animate } from "framer-motion";
import cl from './AboutMe.module.css';
import Photo from '../../images/PhotoMe2.png';

const AboutMe = () => {

    function FadeInWhenVisible({ children }) {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
        >
          {children}
        </motion.div>
      );
    }

    const [visible, setVisible] = useState(false);
      const elementRef = useRef();

      useEffect((visible) => {
      const element = elementRef.current;
      console.log(element)
      if (element && element.getBoundingClientRect().top < window.innerHeight) {
         setVisible(!visible);
      } else {
         setVisible(!visible);
      }
      }, []);
   
      function Counter({ from, to }) {
       
         const nodeRef = useRef();
         useEffect(() =>  {
            
           const node = nodeRef.current;
           const controls = animate(from, to, {
             duration: 1.5,
             onUpdate(value) {
               node.textContent = value.toFixed(0);
             },
           });
         
           return () => controls.stop();
         
         }, [from, to]);
         return <p className={cl.num} ref={nodeRef} />;
       }


  return (
    <div className={cl.container}>
      <div className={cl.lr__container}>
        <div className={cl.container__name}>
          <span>Меня зовут</span>
          <span className={cl.container__full_name}>алексей соловьев</span>
        </div>
        <div className={cl.left}>
          <img src={Photo} alt="" className={cl.photo}/>
        </div>
        <div className={cl.right}>
          <b><span className={cl.right__name}>Меня зовут</span>   <span className={cl.color}>алексей соловьев</span> <br /> <br /> я Профессиональный программист с более чем 20-ти летним стажем.
          Организовал свою первую компанию в 2008 году.
          Я буду обучать и наставлять тебя.</b> <br /> <br />

          влёти в айти – это аккредитованная Министерством Связи и Массовых Коммуникаций IT компания. Совмещай обучение и заработок в Vleti.V.AiTi с учебой в ВУЗе, используй отсрочку от армии и академические отпуска.
        </div>
      </div>
         {visible
         ?  <FadeInWhenVisible>
         <div className={cl.line}>
           <div className={cl.block}>
             <Counter id="23"
             from={0}
             to={23}
              className={cl.num}>
               0
             </Counter>
             <p className={cl.desc}>года опыта в программировании</p>
           </div>
           <div className={cl.block}>
             <Counter
             from={0} to={15}
             id='15'
              className={cl.num}>
               15
             </Counter>
             <p className={cl.desc}>лет моей
               it компании</p>
           </div>
           <div className={cl.block}>
             <Counter
             from={0} to={20}
             id='20'
             className={cl.num}>
               20
             </Counter>
             <p className={cl.desc}>созданных
               it проектов</p>
           </div>
         </div>
         </FadeInWhenVisible>
         : <></>
         }
      <a ref={elementRef} href='#FORM' style={{ textDecoration: 'none' }}>
        <button className={cl.button}>ХОЧУ НА ОБУЧЕНИЕ</button>
      </a>
    </div>
  );
};

export default AboutMe;