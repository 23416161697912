import React from 'react'
import AboutImg from '../images/About.png'
import mobileMountaintsFirst from '../images/mobile_mountains.png'
import mobileMountaints from '../images/mobile_mountains2.png'

const About = () => {
   return (
   <div className='about__container'>
      <div className="about__text">
            <b><span className="about__logo">Влети в АйТи</span><span className="about__logo-adaptive">Влети в Айти</span> - это современное программирование и самые прибыльные проекты, которые ты сможешь воплотить в жизнь!
            </b><br />
           <span className='about__beWithUs'>Будь с нами и:</span> 
         <ul className='about__ul'>
            <li>разрабатывай ПО</li>
            <li>работай с крупнейшими CRM системами</li>
            <li>разрабатывай приложения</li>
            <li>создавай стартапы</li>
         </ul>
         </div>
         <img src={AboutImg} alt="about" className='about__img' />
         <div className='about__mobile-img'>
            <img src={mobileMountaintsFirst} alt="about" className='about__mobile-img-first' />
            <img src={mobileMountaints} alt="about" className='about__mobile-img-second' />
         </div>
         
      </div>
   )
};

export default About;