import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../styles/ContractOffer.css';

const ContractOffer = () => {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <div className="offer">
         <div className="offer__back">
            <Link to="/" className="offer__back-text" >Главная страница</Link>
            <span className="offer__back-text">/</span>
            <span className="offer__back-text">Договор оферты</span>
         </div>
         
         <div className="offer__date">
            <span className="offer__text">Дата: 19.07.2024 г.</span>
         </div>
         <div className="offer__title">
            <span className="offer__title_text">Публичная оферта</span>
         </div>
         <div className="offer__wrapper">

            <div className="offer__block">
               <p className="offer__text">Настоящая публичная оферта является предложением оферента</p>
            </div>

            <div className="offer__block">
               <p className="offer__text">ИП Соловьев Алексей Сергеевич, ИНН 631302369240, ОГРНИП 320631300043871</p>
            </div>

            <div className="offer__block">
                <p className="offer__text"> Заключить договор на изложенных далее по тексту условиях, с любым лицом, заинтересованным в получении услуг (далее по тексту — Пользователь).</p>
               <p className="offer__text">Под акцептом настоящей публичной оферты понимается совершение регистрационных действий на сайте <a href="https://vletivaiti.ru">vletivaiti.ru</a> (далее Сайт), при которых Пользователь в явном виде указывает свои персональные данные или такие данные передаются в автоматическом режиме от сторонних сервисов (Социальных сетей, Мессенджеров, Операторов связи и других).</p>
               <p className="offer__text">Регистрационные действия совершаются в виде:</p>
               <ul className="offer__list">
                  <li className="offer__text offer__item_li">Заполнения формы на странице Сайта с указанием персональных данных (имени, фамилии, номера телефона, адреса электронной почты или других).</li>
                  <li className="offer__text offer__item_li">Вступления в сообщество Сервиса в Социальных сетях (Facebook, ВКонтакте, Instagram) (или «лайк» страницы сообщества, или «подписка» на сообщество, или принятие приглашения на вступление в сообщество).</li>
                  <li className="offer__text offer__item_li">Заполнения формы на странице Сайта, встроенной в Социальную сеть в рамках сообщества Сервиса.</li>
                  <li className="offer__text offer__item_li">Отправки сообщения сообществу Сервиса в Социальной сети или комментирования другого сообщения в сообществе.</li>
                  <li className="offer__text offer__item_li">Подключении, добавлении в список контактов или запуск автоматического контакта Сервиса («бота») в Мессенджере (Telegram, Viber, WhatsApp, VK Messenger, Facebook Messenger, Instagram).</li>
                  <li className="offer__text offer__item_li">Отправки сообщения контакту Сервиса (в том числе «боту» и по номеру телефона) с помощью Мессенджера.</li>
                  <li className="offer__text offer__item_li">Отправки email-сообщения на адреса электронной почты Сервиса.</li>
                  <li className="offer__text offer__item_li">Телефонного вызова на номера телефонов Сервиса.</li>
                  <li className="offer__text offer__item_li">Отправки SMS-сообщения на номера телефонов Сервиса.</li>
               </ul>
            </div>

            <div className="offer__block">
               <p className="offer__text_bold">В случае акцепта настоящей публичной оферты, лицо, намеренное получить информацию на Сайте и/или получить доступ к платным или бесплатным услугам Сервиса и/или совершить любые иные действия на Сайте, понимает и подтверждает, что:</p>
               <ul className="offer__list">
                  <li className="offer__text_bold offer__item_li">Текст публичной оферты понятен, каких-либо вопросов на момент акцепта публичной оферты у Пользователя услуг нет.</li>
                  <li className="offer__text_bold offer__item_li">Согласно пп. 5 п. 1 ст. 6 ФЗ РФ «О персональных данных», допускается обработка персональных данных в случае, если она необходима для исполнения договора, стороной которого является субъект персональных данных. Акцепт настоящей публичной оферты означает согласие на обработку персональных данных лица, намеренного получить услуги Сервиса.</li>
                  <li className="offer__text_bold offer__item_li">
                     Сервис вправе выполнять рассылку уведомлений рекламно-информационного характера пользователю, зарегистрированному на Сервисе, с использованием контактной информации (персональных данных, явно указанные Пользователем при регистрации или ином использовании Сервиса, а также с использованием данных, полученных в автоматическом режиме от сторонних сервисов (Социальных сетей, Мессенджеров, Операторов связи). Рекламно-информационные сообщения могут быть направлены посредством:
                     <ol className="offer__list">
                        <li className="offer__text_bold">отправки email-сообщений;</li>
                        <li className="offer__text_bold">отправки SMS-сообщений;</li>
                        <li className="offer__text_bold">отправки сообщений с помощью Мессенджеров;</li>
                        <li className="offer__text_bold">отправки сообщений с помощью Социальных сетей;</li>
                        <li className="offer__text_bold">телефонных звонков, в т. ч. автоматических.</li>
                     </ol>
                  </li>
                  <li className="offer__text_bold offer__item_li">Сервис вправе делать рассылку рекламно-информационного характера не только о своих услугах и предложениях, но и производить рассылку рекламно-информационного характера своих партнеров, то есть юридических лиц, индивидуальных предпринимателей, самозанятых граждан и физических лиц, сотрудничающих с Сервисом.</li>
               </ul>
               <p className="offer__text_bold">Все вопросы, не урегулированные настоящей публичной офертой, решаются в соответствии с действующим законодательством Российской Федерации.</p>
            </div>

            <div className="offer__block">
               <div className="offer__title">
                  <span className="offer__subtitle">Договор на предоставление услуг онлайн-школы</span>
                  <span className="offer___subtitle_min">(существенные условия)</span>
               </div>
               <p className="offer__text_bold">1. Термины и определения</p>
               <p className="offer__text">1.1. «Онлайн-школа» — услуга предоставления информации посредством сети Интернет, размещенная на Сервисе. </p>
               <p className="offer__text">1.2. «Сервис» — интернет-сайт, по адресу которого, и другие технические средства, посредством которых, обеспечивается функционирование Онлайн-школы. </p>
               <p className="offer__text">1.3. «Онлайн-курс» — информационное произведение, в том числе аудиовизуальное, специально созданное для его показа в сети Интернет и состоящее из определенного количества Уроков. </p>
               <p className="offer__text">1.4. «Урок» — часть Онлайн-курса, логически объединяющая часть информации Онлайн-курса и, при необходимости, содержащая требования (задания), ограничивающие доступ к последующим Урокам или накладывающие иные ограничения на возможность Прохождения онлайн-курса.</p>
               <p className="offer__text">1.5. «Прохождение онлайн-курса» — процесс потребления информации Онлайн-курса, нацеленный на получение информации, или знаний, или навыков, содержащихся в Онлайн-курсе, а также включающий в себя выполнение обязательных или необязательных заданий из уроков.</p>
               <p className="offer__text">1.6. «Ученик», то же что и «Пользователь» — физическое лицо, которое произвело акцепт публичной оферты для возможности пользоваться всеми услугами и всей информацией, предоставленной на Сайте. </p>
               <p className="offer__text">1.7. «Аккаунт Ученика» — персональное пространство Ученика на Сервисе. Аккаунт Ученика является персонифицированным. </p>
               <p className="offer__text">1.8.«Преподаватель» — лицо, которое оказывает услуги предоставления информации и/или проверки Ответов Учеников на Сервисе.</p>
               <p className="offer__text">1.9. «Ответ Ученика» — выполненное Учеником задание, которое он предоставляет Преподавателю для последующей проверки. Проверка может выполняться Преподавателем или в автоматическом режиме. В результате проверки Ответ может быть отклонен, что расценивается, как невыполнение Задания и накладывать ограничение на Прохождение Онлайн-курса.</p>
               <p className="offer__text">1.10. «Стоп-урок» — Урок, ограничивающий доступ Ученика к последующим урокам до момента выполнения Учеником требований, заложенных в Стоп-уроке.</p>
               <p className="offer__text">1.11. «Вебинар» — интерактивное онлайн-мероприятие с участием Преподавателя Онлайн-курса или запись такого онлайн-мероприятия.</p>
               <p className="offer__text">1.12. «Администратор Сервиса» — лицо, являющееся инициатором оферты, предоставляющее услуги доступа к Онлайн-курсам и/или Вебинарам и осуществляющее контроль за действиями Учеников.</p>
               <p className="offer__text">1.13. «Условия прохождения онлайн-курса» — комплекс требований и ограничений, определяемый Администратором Сервиса, который определяет условия для получения доступа Учеником к Онлайн-курсу и определяет возможности, предоставляемые Ученику при прохождении онлайн-курса. Разным Ученикам могут предъявляться разные требования и предоставляться различные возможности.</p>
            </div>

            <div className="offer__block">
               <p className="offer__text_bold">2. Предмет договора</p>
               <p className="offer__text">2.1. Предметом настоящего Договора является:</p>
               <ul className="offer__list">
                  <li className="offer__text offer__item_li">предоставление услуги доступа к Онлайн-курсам с целью получения информации Учениками;</li>
                  <li className="offer__text offer__item_li">предоставление услуги доступа к Вебинарам;</li>
                  <li className="offer__text offer__item_li">предоставление услуги проверки Ответа Ученика  с целью проверки правильности выполнения задания Учеником. Услуга проверки Ответа Ученика  предоставляется в случае, если она будет предусмотрена соответствующим Онлайн-курсом и Условиями прохождения Онлайн-курса. </li>
               </ul>
            </div>

            <div className="offer__block">
               <p className="offer__text_bold">3. Порядок предоставления услуг</p>
               <p className="offer__text">3.1. Предоставление услуги доступа к Онлайн-курсу производится следующим образом:</p>
               <ul className="offer__list">
                  <li className="offer__text offer__item_li">Доступ к Онлайн-курсу предоставляется в Аккаунте Ученика. </li>
                  <li className="offer__text offer__item_li">Доступ к Онлайн-курсу может быть ограничен периодом, определяемым Условиями прохождения Онлайн-курса. </li>
                  <li className="offer__text offer__item_li">Администратор сервиса вправе изменить период доступа к Онлайн-курсу. </li>
                  <li className="offer__text">Онлайн-курс может содержать один и более Стоп-уроков. Количество Стоп-уроков устанавливается Администратором Сервиса.</li>
                  <li className="offer__text offer__item_li">В случае если Ученик не выполнил требования Стоп-урока или иные требования, заложенные в Условиях прохождения Онлайн-курса, доступ к последующим Урокам Онлайн-курса Ученику не предоставляется до момента выполнения необходимых требований, но в рамках периода доступа к Онлайн-курсу и в соответствии с Условиями прохождения онлайн-курса.</li>
               </ul>
               <p className="offer__text">3.2. Ученику, по взаимному согласованию с Администратором Сервиса, может быть предоставлена возможность продления доступа к Онлайн-курсу в случае, если по каким-либо уважительным причинам Ученик не смог выполнить Процесс прохождения Онлайн-курса в установленный для Онлайн-курса период.</p>
               <p className="offer__text">3.3. Предоставление услуги доступа к Вебинарам производится следующим образом:</p>
               <ul className="offer__list">
                  <li className="offer__text offer__item_li">Вебинары проводятся в прямой трансляции или в записи, в т. ч. с имитацией действий Преподавателя и других зрителей. Порядок получения доступа к Вебинару определяется Администратором Сервиса. </li>
                  <li className="offer__text offer__item_li">По факту завершения Вебинара Ученикам может быть предоставлена запись Вебинара. Порядок предоставления доступа к записи Вебинара определяется Администратором Сервиса.</li>
                  <li className="offer__text offer__item_li">При наличии технической возможности, Администратор Сервиса предоставляет Ученику специальную форму общения (чата) с Преподавателем во время Вебинара.</li>
               </ul>
            </div>

            <div className="offer__block">
               <p className="offer__text_bold">4.Стоимость услуг и порядок оплаты</p>
               <p className="offer__text">4.1. Факт оплаты стоимости Онлайн-курса и/или Вебинара означает, что Ученик ознакомлен и согласен с представленной на Сервисе информацией об Онлайн-курсе и/или Вебинаре, намерен выполнить Прохождение Онлайн-курса или прослушать Вебинар. </p>
               <p className="offer__text">4.2. Услуги подлежат оплате посредством платежных систем, информация о которых доступна на Сервисе.  </p>
               <p className="offer__text">4.3. После выбора Онлайн-курса и/или Вебинара в Аккаунте Ученика формируется счет на оплату. Сервис может продублировать счет на оплату на электронный почтовый адрес Ученика или иными способами с использованием известных контактных данных Пользователя.</p>
               <p className="offer__text">4.4. Счет на оплату должен быть оплачен Учеником в течение 3 (Трех) рабочих дней со дня получения, если иной срок не будет указан в счете или в Аккаунте Ученика. </p>
               <p className="offer__text">4.5. В случае просрочки оплаты доступ к Онлайн-курсу с и/или Вебинару Ученику не предоставляется. </p>
               <p className="offer__text">4.5. Частичная оплата счета и условия предоставления услуг при частичной оплате допускается по решению Администратора Сервиса.</p>
               <p className="offer__text">4.6. Сервисом могут быть предоставлены дополнительные услуги, связанные с Онлайн-школой. Подробная информация об услуге, ее стоимости, сроках проведения и иные существенные сведения должны будут быть указаны на Сервисе. Факт оплаты таких дополнительных услуг будет означать согласия Ученика со всей информацией об услугах, предоставленных Сервисом. К дополнительным услугам,   применяются все положения об Онлайн-курсах или Вебинарах, если в информации о дополнительных услугах не будет указано иное.</p>
            </div>

            <div className="offer__block">
               <p className="offer__text_bold">5.Ответственность </p>
               <p className="offer__text">5.1.Ученик несет ответственность за несоответствие указанной им информации при регистрации и осознает весь риск неблагоприятных последствий, связанных с ее неверным указанием. Администратор Сервиса не проверяет достоверность предоставленных Учеником данных. </p>
               <p className="offer__text">5.2. Ученик несет ответственность за размещение на Сервисе объектов интеллектуальной собственности, таких как фотографии, картинки, рисунки, статьи, логотипы, иные объекты, которым согласно действующему законодательству Российской Федерации предоставлена правовая охрана. В случае предъявления к Администратору Сервиса претензий третьих лиц, касающихся указанных объектов, Ученик обязан самостоятельно их урегулировать. В случае, если к Администратору Сервиса будет предъявлен иск о защите права, вследствие которого Администратор Сервиса понесет убытки, Ученик возмещает такие убытки в течение одного месяца со дня предъявления Администратором Сервиса требования о возмещении. </p>
               <p className="offer__text">5.3.Ученик несет ответственность за копирование и дальнейшее распространение Онлайн-курса и/или Вебинара и/или иной аудиовизуальной информации предоставляемой Сервисом как целиком так и любой их части. Администратор Сервиса при обнаружении нарушений, указанных в настоящем пункте, вправе заблокировать Аккаунт Ученика (в том числе с приостановкой предоставления услуг), предъявив ему соответствующую претензию, а также обратиться в судебный орган для защиты своих прав и взыскать с Ученика в судебном порядке убытки или компенсацию. В случае если будет установлено, что Ученик является участником и/или распространителем Онлайн-курса (включая части Онлайн-курса) на сервисах совместных покупок информационных продуктов (складчина), Ученик будет обязан выплатить Онлайн-школе штраф в размере 1 000 000,00 (Один миллион) рублей. </p>
               <p className="offer__text">5.4.Ученик несет ответственность за предоставление третьим лицам доступа к своему Аккаунту. В случае предоставления доступа третьим лицам, Ученик выплачивает Администратору Сервиса штраф в размере 300 000,00 (Триста тысяч рублей) за каждый случай нарушения. </p>
            </div>
            <div className="offer__block">
               <p className="offer__text_bold">6. Отказ от услуги Сервиса</p>
               <p className="offer__text">6.1. В случае если Ученик оплатил Онлайн-курс или другую услугу, но впоследствии принял решение отказаться от Прохождения Онлайн-курса или от иной платной услуги Сервиса, он обязан уведомить об этом Администратора Сервиса, направив ему соответствующее уведомление посредством средств обратной связи, указанной на Сервисе. </p>
               <p className="offer__text">6.2. Все без исключения возвраты денежных средств Ученику перечисляются за вычетом комиссий платежных систем, посредством которых Ученик оплачивал услуги, а также за вычетом  фактических расходов, которые возникли у Сервиса для предоставления доступа к Онлайн-курсу и/или Вебинару и/или дополнительным услугам, а в случае если Ученик приступил к Прохождению Онлайн-курса или иному потреблению другой услуги, но впоследствии принял решение отказаться от услуг Сервиса, то Сервис помимо комиссии платежной системы и фактических расходов, удерживает стоимость услуг, фактически оказанных на дату отказа.</p>
               <p className="offer__text">6.3. Уведомление Ученика об отказе от услуг должно быть предоставлено не позднее, чем за 5 (Пять) дней до даты отказа, указанного в Уведомлении. </p>
               <p className="offer__text">6.4.Возврат денежных средств, оплаченных Учеником, производится тем же способом, что и их оплата или иным способом по согласованию Ученика с Администратором Сервиса.</p>
            </div>
            <div className="offer__block">
               <p className="offer__text_bold">7.Особые условия</p>
               <p className="offer__text">7.1.  Сервис вправе использовать Ответы Учеников любым способом, без каких-либо ограничений.</p>
               <p className="offer__text">7.2.  Замена Онлайн-курса по требованию Ученика производится исключительно по решению Администратора Сервиса и в случае наличия такой возможности, в частности, в случае наличия свободных мест на Онлайн-курсе. </p>
            </div>
            <div className="offer__block">
               <p className="offer__text_bold">8. Заключительные положения </p>
               <p className="offer__text">8.1. Настоящее Соглашение действует бессрочно.</p>
               <p className="offer__text">8.2. По спорным вопросам Сторонами согласована процедура досудебного порядка урегулирования споров. </p>
               <p className="offer__text">8.3.Претензия в адрес Сервиса должна быть передана на электронный почтовый адрес, указанный на Сервисе, с указанием в теме письма «Спорный вопрос». Администратор Сервиса отправляет претензию на электронный почтовый адрес Ученика, указанный им в момент регистрации.</p>
               <p className="offer__text">8.4. При отсутствии соглашения по спорному вопросу в течение 35 (Тридцати пяти) дней со дня получения претензии Сервисом или Учеником, заинтересованная сторона вправе обратиться в судебный орган Российской Федерации по месту нахождения Администратора Сервиса. </p>
               <p className="offer__text">8.5. Сервис не несет ответственности за сбои, возникающие в телекоммуникационных и энергетических сетях или сбои оборудования, обеспечивающих функционирование Сервиса; действия вредоносных программ, повлекшие прекращение или приостановление работы сети Интернет как в общем, так и в отдельных сегментах сети, задействованных при исполнении настоящего Соглашения; за противоправные действия третьих лиц, направленные на несанкционированный доступ и (или) выведение Сервиса из строя.</p>
               <p className="offer__text">8.6. Настоящее Соглашение может быть изменено Администратором Сервиса в любой момент, в связи с чем на Пользователя возложена обязанность еженедельно проверять актуальность текста настоящего Соглашения и внимательно читать все опубликованные изменения. </p>
               <p className="offer__text">8.7. Администратор Сервиса предпримет все необходимые меры по предварительному уведомлению Пользователя об изменении текста настоящего Соглашения, в частности, посредством рассылок сообщений информационного характера на адреса электронной почты, указанные при регистрации. Тем не менее, обязанность Пользователя регулярно просматривать настоящее Соглашение является приоритетной, и Ученик не может предъявлять претензии к Администрации Сервиса о своем несогласии с изменениями по основаниям неполучения соответствующего уведомления. </p>
               <p className="offer__text">8.7. В случае если Администратор Сервиса предоставил Ученику доступ к Онлайн-курсу и/или Вебинару на бесплатной основе, к таким услугам Закон «О защите прав потребителей» не применяется. </p>
               <p className="offer__text">8.9. Сервис не несет какой-либо ответственности за выдачу поисковым роботом любой поисковой системы фотографии Ученика, в случае если Ученик разместил свою фотографию в своем Аккаунте.</p>
            </div>
         </div>

      </div>
   )
};

export default ContractOffer;
