
export const sendData = async (url, method, data, param = '', apiKey = '') => {
	
	console.log(data);

	const respons = await fetch(url + param + apiKey, {
		method: method,
		body: JSON.stringify(data),
	});

	if (!respons.ok) {
		throw new Error(`Ошибка по адресу: ${url}, статус ошибки ${respons}`);
	}

	return await respons.json()
}
